<template>
  <div class="advertisingManagement-view">
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="type" align="center" label="图片位置" >
          <div class="operating" slot-scope="scope">
            <p>{{scope.row.type == 1 ? '商城版块' : scope.row.type == 2 ? '预约版块' : "动态版块"}}</p>
          </div>
        </el-table-column>

        <el-table-column prop="num" align="center" label="图片数量" />
        <el-table-column label="操作" align="center">
          <div class="operating" slot-scope="scope">
            <el-button size="mini" type="text" @click="edit_tap(scope.row)">编辑</el-button>
          </div>
        </el-table-column> 
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchPhotoType
} from "@/api/companyManage";
export default {
  name: "advertisingManagement",
  components: {
    commonTable,
  },
  data() {
    return {
      // tableData: [{
      //   type:1,
      //   num:0
      // },{
      //   type:2,
      //   num:0
      // },{
      //   type:3,
      //   num:0
      // }],
      tableData: [{
        type:3,
        num:0
      }],
      currentPage: 1, //当前页数
      pageNum: 1,
      loading:false,
      total: 0, //总条数
      pageSize: 10,
    };
  },
  mounted(){
    this.getPhoto()
  },
  methods:{
    getPhoto(){
      this.loading = true
      fetchPhotoType({ignore:true})
      .then(res=>{
        this.loading = false
        res.data.map(res=>{
          this.tableData[res.type -1].num = res.num
        })
        this.total = this.tableData.length
      })
      .catch(err=>{
        this.loading = false
      })
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getPhoto();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getPhoto();
    },
    //编辑
    edit_tap(item){
      let {type,num} = item
      
      this.$router.push(`./setBanner?type=${type}`) 
    }
  }
};
</script>

<style lang="scss" scoped>
.advertisingManagement-view{
  background: #ffff;
  padding: 24px;
}
.operating{
  .el-button--text{
    color: #51CBCD;
  }
}
</style>